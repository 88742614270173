import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as HomeStore from '../store/Home';
import './Contacts.css';
import Jumbotron from './Jumbotron';
import About from './About';
import Services from './Services';
import Pricing from './Pricing';
import Contact from './Contact';
import Footer from './Footer';

type HomeProps =
    HomeStore.HomeState &
    typeof HomeStore.actionCreators &
    RouteComponentProps<{}>;

class Home extends React.PureComponent<HomeProps> {
    public render() {
        return <React.Fragment>

            <Jumbotron />

            <About />

            {/* <!-- Container (Services Section) --> */}
            <Services />

            {/* <!-- Container (Portfolio Section) --> */}
            {/* <Portfolio/> */}

            <Pricing />
            {/* <!-- Container (Contact Section) --> */}
            <Contact />
            {/* <!-- Image of location / map-- > */}

            <Footer />

        </React.Fragment>

    }
}
export default connect(
    (state: ApplicationState) => state.pricingPerMonth,
    HomeStore.actionCreators
)(Home);
