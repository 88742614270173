import React from 'react';
import Slider from './Slider';

class Pricing extends React.PureComponent<{}, { isYear: boolean }> {
    public state = {
        isYear: false
    };

    public toggle = () => {
        this.setState({
            isYear: !this.state.isYear
        });
    }
    render() {
        const { isYear } = this.state
        return <div>
            <div id="pricing" className="container-fluid">
                <div className="text-center">
                    <h2>Pricing</h2>
                    <h4>Choose a service that suits you {this.state.isYear}</h4>
                    <Slider isOn={this.state.isYear} toToggle={this.toggle} />
                </div>
                <div className="card-deck slideanim">
                    {isYear ?
                        <React.Fragment>
                            <Card {...{ title: "Promotional page", price: 20, info: "per month" }} >
                                <div className="card-text">
                                    <p><strong>Web mail configuration* </strong>included</p>
                                    <p><strong>Domain name </strong> not included</p>
                                    <p><strong>Hosting </strong>included</p>
                                </div>
                            </Card>
                            <Card {...{ title: "e-commerce", price: 25, info: "per month" }} >
                                <div className="card-text">
                                    <p><strong>Web mail configuration* </strong>included</p>
                                    <p><strong>Domain name </strong> included</p>
                                    <p><strong>Hosting  </strong>not included</p>
                                </div>
                            </Card>
                            <Card {...{ title: "Custom solutions", price: 50, info: "per month" }} >
                                <div className="card-text">
                                    <p><strong>Web mail configuration* </strong>included</p>
                                    <p><strong>Domain name </strong>  included</p>
                                    <p><strong>Hosting </strong>not included</p>
                                </div>
                            </Card>
                        </React.Fragment>
                        : <React.Fragment>
                            <Card {...{ title: "Promotional page", price: 200, info: "" }} >
                                <div className="card-text">
                                    <p><strong>Web mail configuration* </strong>included</p>
                                    <p><strong>Domain name </strong>included</p>
                                    <p><strong>Hosting </strong>not included</p>
                                </div>
                            </Card>
                            <Card {...{ title: "e-commerce", price: 250, info: "" }} >
                                <div className="card-text">
                                    <p><strong>Web mail configuration* </strong>included</p>
                                    <p><strong>Domain name </strong> included</p>
                                    <p><strong>Hosting </strong>not included</p>
                                </div>
                            </Card>
                            <Card {...{ title: "Custom solutions", price: 300, info: "" }} >
                                <div className="card-text">
                                    <p><strong>Web mail configuration* </strong>included</p>
                                    <p><strong>Domain name </strong> included</p>
                                    <p><strong>Hosting </strong>not included</p>
                                </div>
                            </Card>
                        </React.Fragment>
                    }
                </div>
            </div >
        </div >
    }
}
export default Pricing;

class Card extends React.PureComponent<{ title: string, price: number, info: string }, {}>{
    render() {
        return <div className="card ">
            <div className="card-body text-center">
                <h2 className="card-title">{this.props.title}</h2>
                {this.props.children}

                <div className="card-footer">
                    <h4 className='mb-0'>from</h4>
                    <h3>€{this.props.price}</h3>
                    <h4>{this.props.info}</h4>
                    <a className="btn btn-lg slide-link" href='#contact'>Ask</a>
                </div>
            </div>
        </div>
    }
}

