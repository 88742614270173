import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as ShopStore from '../store/Shop';

type ShopProps =
    ShopStore.ShopState &
    typeof ShopStore.actionCreators &
    RouteComponentProps<{}>;

class Shop extends React.PureComponent<ShopProps> {
    public render() {
        return (
            <React.Fragment>
                <h1>Shop</h1>

                <p>This is a simple example of a React component. Shop</p>

                <p aria-live="polite">Current count: <strong>{this.props.shopper}</strong></p>

                <button type="button"
                    className="btn btn-primary btn-lg"
                    onClick={() => { this.props.increment1(); }}>
                    Increment
                </button>

                <button type="button"
                    className="btn btn-primary btn-lg"
                    onClick={() => { this.props.decrement1(); }}>
                    Decrement
                </button>
            </React.Fragment>
        );
    }
};

export default connect(
    (state: ApplicationState) => state.shopper,
    ShopStore.actionCreators
)(Shop);
