import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as ItemsStore from '../store/Items';

// At runtime, Redux will merge together...
type ItemsProps =
  ItemsStore.ItemsState // ... state we've requested from the Redux store
  & typeof ItemsStore.actionCreators // ... plus action creators we've requested
  & RouteComponentProps<{ startDateIndex: string }>; // ... plus incoming routing parameters


class FetchData extends React.PureComponent<ItemsProps> {
  // This method is called when the component is first added to the document
  public componentDidMount() {
    this.ensureDataFetched();
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    this.ensureDataFetched();
  }

  public render() {
    return (
      <React.Fragment>
        <h1 id="tabelLabel">Weather forecast 1</h1>
        <p>This component demonstrates fetching data from the server and working with URL parameters.</p>
        {this.renderForecastsTable()}
        {this.renderPagination()}
      </React.Fragment>
    );
  }

  private ensureDataFetched() {
    const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
    this.props.requestWeatherForecasts(startDateIndex);
  }

  private renderForecastsTable() {
    var tempForecasts: ItemsStore.Item[][] = [];
    const { forecasts } = this.props
    if (forecasts.length <= 0)
      return <div>No forecasts data found</div>

    const chunk = 3
    forecasts.forEach((item, index) => {
      if (index % chunk === 0) {
        tempForecasts.push(forecasts.splice(index, index += chunk));
      }
    });

    if (tempForecasts.length <= 0)
      return <div>No tempForecasts data found</div>

    return <div>
      {
        tempForecasts.map((forecastRow, index) => {
          return < div className='card-deck' key={index}>
            {forecastRow.map((forecast: ItemsStore.Item) =>
              <div className='card' key={forecast.date}>
                  <div className='card-body'>
                    <span>{forecast.date}</span>
                    <span>{forecast.temperatureC}</span>
                    <span>{forecast.temperatureF}</span>
                    <span>{forecast.summary}</span>
                  </div>
              </div>
            )}
          </div >
        })
      }
    </div>
  }

  private renderPagination() {
    const prevStartDateIndex = (this.props.startDateIndex || 0) - 5;
    const nextStartDateIndex = (this.props.startDateIndex || 0) + 5;

    return (
      <div className="d-flex justify-content-between" >
        <Link className='btn btn-outline-secondary btn-sm' to={`/fetch-data/${prevStartDateIndex}`}>Previous</Link>
        { this.props.isLoading && <span>Loading...</span>
        }
        < Link className='btn btn-outline-secondary btn-sm' to={`/fetch-data/${nextStartDateIndex}`} > Next</Link >
      </div >
    );
  }
}

export default connect(
  (state: ApplicationState) => state.weatherForecasts, // Selects which state properties are merged into the component's props
  ItemsStore.actionCreators // Selects which action creators are merged into the component's props
)(FetchData as any);
