import React, { Component } from 'react';

class Contact extends Component<{}, {
    name: string,
    email: string,
    comment: string,
}>
{
    public state = {
        name: '',
        email: '',
        comment: '',
    };

    // public handleInput = (value, name) => {
    //     this.setState({
    //         isYear: !this.state.isYear
    //     });
    // }

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        const name = e.target.name;
        this.setState({ ...this.state, [name]: newValue });
    }

    onSubmit = () => {
        const { email, name, comment } = this.state;
        $.post("welcome.php", { email, name, comment }, function (data) {
            alert(data);
        })
            .fail(function () {
                alert("error");
            });
    }

    render() {
        return <div>
            <div id="contact" className="container-fluid bg-grey">
                <h2 className="text-center">CONTACT</h2>
                <div className="row">
                    <div className="col-sm-5">
                        <p>Contact us and we'll get back to you within 24 hours.</p>
                        <p><span className="glyphicon glyphicon-map-marker"></span> Vilnius, Lithuania</p>
                        <p><span className="glyphicon glyphicon-envelope"></span> admin@automationproject.eu</p>
                    </div>
                    <form className="col-sm-7 slideanim" action="welcome.php" method="get">
                        <div className="row">
                            <div className="col-sm-6 form-group">
                                <input className="form-control" id="name" name="name" onChange={this.onChange.bind(this)} placeholder="Name" type="text" required />
                            </div>
                            <div className="col-sm-6 form-group">
                                <input className="form-control" id="email" name="email" onChange={this.onChange.bind(this)} placeholder="Email" type="email" required />
                            </div>
                        </div>
                        <textarea className="form-control" id="comments" name="comment" onChange={this.onChange.bind(this)} placeholder="Comment" rows={5}></textarea><br />
                        <div className="row">
                            <div className="col-sm-12 form-group">
                                <button className="btn btn-light pull-right" type="button" onClick={this.onSubmit.bind(this)}>Send</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* <img src="/w3images/map.jpg" className="w3-image w3-greyscale-min" style={{ width: '100%' }} alt='alt'></img> */}
        </div>
    }
}

export default Contact;