import React from 'react';
import './Slider.css'

class Slider extends React.Component<{ isOn: boolean, toToggle: Function }, {}> {
    render() {
        return <div>
            <label className='pr-2'>Month</label>
            <label className="switch py-1">
                <input type="checkbox" value={1} onClick={() => { this.props.toToggle(); }} />
                <span className="slider round"></span>
            </label>
            <label className='pl-2'>Year</label>
        </div>

    }
}
export default Slider

