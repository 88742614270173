import * as React from 'react';

const Services = () => <React.Fragment>
    <div id="services" className="container-fluid text-center">
        <h2>SERVICES</h2>
        <h4>What we offer</h4>
        <div className="row slideanim">
            <div className="col">
                <span className="glyphicon glyphicon-plane logo-small"></span>
                <h4>SPEED</h4>
                <p>On your depand we cant create a solution in DAYS!</p>
                <p>Especialy if you choose from our <a href='http://shop.automationproject.eu'>templates</a></p>
            </div>
            <div className="col">
                <span className="glyphicon glyphicon-certificate logo-small"></span>
                <h4>Quality</h4>
                <p>We are responsible for quality and we provide a lifetime warranty on our products</p>
            </div>
            <div className="col">
                <span className="glyphicon glyphicon-check logo-small"></span>
                <h4>FIT NEEDS</h4>
                <p>We work on different technologies, but we are also fast learning, we can support on develop your legacy solutions or just rewrite them. We prefer .net, React, Xamarin.</p>
            </div>
        </div>
    </div>
</React.Fragment>

export default Services