import * as React from 'react';

const Jumbotron = () => (
    <React.Fragment>
        <div className="jumbotron text-center">
            <h1>Automation Projects eu</h1>
            <p>We specialize in individual & quick IT solutions</p>
            <span>Want to find out more, just enter your email below.</span>
            <span>No spam</span>

            <div className="input-group mb-1">
                <input type="email" className="form-control" placeholder="Email Address" required />
                <div className="input-group-prepend">
                    <button type="button" className="btn btn-danger">Contact me!</button>
                </div>
            </div>
        </div>
    </React.Fragment>)
export default Jumbotron