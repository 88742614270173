import * as React from 'react';

const About = () => <React.Fragment>
    <div id="about" className="container-fluid">
        <div className="row">
            <div className="col-8">
                <h2>About </h2>
                <h4>We are company that provides a variety of services, from small companies to big companies.</h4>
                <p>Small companies can expect consulting in their first steps of getting an optimization tools or first steps in huge ocean of internet. For a big companies we can offer a complex solutions in optimizing processes.</p>
                <a className="btn btn-light btn-lg slide-link" href='#contact' >Get in Touch</a>
            </div>
            <div className="col-4">
                <span className="glyphicon glyphicon-ok logo"></span>
            </div>
        </div>
    </div>
    
    <div className="container-fluid bg-grey">
        <div className="row">
            <div className="col-4">
                <span className="glyphicon glyphicon-globe logo slideanim"></span>
            </div>
            <div className="col-8">
                <h2>Our Values</h2>
                <h4><strong>MISSION:</strong> Our mission lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</h4>
                <p><strong>VISION:</strong> Our vision Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            </div>
        </div>
    </div>
</React.Fragment>

export default About