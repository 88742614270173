import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };

    public render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="#myPage" href="#myPage">Automation Project</NavbarBrand>
                        <NavbarToggler onClick={this.toggle} className="mr-2" />
                        <Collapse className="collapse navbar-collapse" isOpen={this.state.isOpen} navbar>
                            <ul className="nav navbar-nav ml-auto">
                                <li><a href="#about">ABOUT</a></li>
                                <li><a href="#services">SERVICES</a></li>
                                {/* <li><a href="#portfolio">PORTFOLIO</a></li> */}
                                <li><a href="#pricing">PRICING</a></li>
                                <li><a href="#contact">CONTACT</a></li>
                                {/* {
                                    [
                                        { value: "/", linkName: "Home" },
                                        { value: "/counter", linkName: "Counter" },
                                        { value: "/fetch-data", linkName: "Fetch data" },
                                        { value: "/shop", linkName: "Shop" },
                                        { value: "/items", linkName: "Items" },
                                        { value: "/contacts", linkName: "Contacts" }
                                    ].map((item) => <NavItem key={item.linkName} >
                                        <NavLink tag={Link} className="text-dark" to={item.value}>{item.linkName}</NavLink>
                                    </NavItem>)
                                } */}
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}
