import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import Counter from './components/Counter';
import Contacts from './components/Contacts';
import FetchData from './components/FetchData';
import Shop from './components/Shop';
import Items from './components/Items';
import './custom.css'

export default () => (
    <Layout>
        <Route exact path='/contacts' component={Contacts} />
        <Route path='/' component={Home} />
        <Route path='/counter' component={Counter} />
        <Route path='/fetch-data/:startDateIndex?' component={FetchData} />
        <Route path='/shop' component={Shop} />
        <Route path='/items' component={Items} />
    </Layout>
);
